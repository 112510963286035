import React from "react";
import { Link, Typography } from "@mui/material";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import classNames from "classnames";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAmazon } from "@fortawesome/free-brands-svg-icons";
import Reviews from "./Reviews";
import Playlists from "./Playlists";
import Interviews from "./Interviews";

const SelfPublishingBook = () => {
  const data = useStaticQuery(graphql`
    query SelfPublishQuery {
      file(relativePath: { eq: "self-pub-media-cover.png" }) {
        childImageSharp {
          fluid(maxWidth: 750, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div>
      <section className="p-14" id="latest-book">
        <div className="flex flex-wrap-reverse lg:flex-nowrap justify-center items-center gap-10">
          <div style={{ maxWidth: 750 }}>
            <section className="flex flex-col items-center gap-3">
              <div className="text-center pb-2">
                <Typography className="text-5xl" fontFamily={"Impact"}>
                  Yes, You Can Become an Author
                </Typography>
              </div>
              <Typography className="text-center" variant="h6">
                Writing, Self-Publishing, and Marketing Your First Book
              </Typography>
              <div className="flex flex-col gap-4">
                <p className="text-left">
                  Have you always wanted to write a book, or is it a more recent
                  ambition? Maybe you have a completed manuscript but aren't
                  sure what comes next. Every author-to-be has countless
                  questions and at least a few doubts. Lisa May Bennett knows
                  exactly what it's like to turn the seemingly impossible dream
                  of writing and publishing your first book into a reality. And
                  now she wants to help you save valuable time and avoid common
                  pitfalls.
                </p>
                <p className="text-left">
                  In <em>Yes, You Can Become an Author</em>, Lisa shares tips
                  from her own journey self-publishing her debut book. She
                  assembles a road map that covers the main topics for
                  self-publishing success: Laying the Foundation; Writing and
                  Editing; Self-Publishing; and Marketing, Advertising, and
                  Sales. Chapters include Your Author Identity, Social Media,
                  Cover Design, Profiling and Finding Your Audience, and much
                  more.
                </p>
                <p className="text-left">
                  <em>Yes, You Can Become an Author</em> introduces a framework
                  centered around taking charge of your future and developing a
                  plan that suits your unique strengths, challenges, and
                  priorities. Lisa believes that with a guidebook in hand, an
                  encouraging cheerleader on your side, and the right resources,
                  you can achieve your publishing dreams!
                </p>
                <p className="text-left">
                  There's a lot to learn and so much to gain, so why not get
                  started today?
                </p>
              </div>
              {/* <div>Purchase now: </div> */}
              <div className="flex flex-wrap gap-2 justify-center">
                <Link
                  target="_blank"
                  rel="noopener"
                  href="https://mybook.to/yesyoucan"
                  underline="none"
                  color="inherit"
                >
                  <Button
                    className="bg-yellow-400 text-black"
                    variant="contained"
                  >
                    Order Now
                  </Button>
                </Link>
                {/* {bookLinks.map((bl, i, { length }) => (
                  <div
                    key={bl.title}
                    className={classNames("after:text-gray-500 after:pl-2", {
                      "after:content-['|']": length - 1 !== i,
                    })}
                  >
                    <Link
                      target="_blank"
                      rel="noopener"
                      href={bl.link}
                      underline="none"
                    >
                      {bl.title}
                    </Link>
                  </div>
                ))} */}
              </div>
            </section>
          </div>
          <div
            style={{
              width: 600,
              minWidth: 200,
              position: "relative",
              // left: "-47px",
            }}
          >
            <Img
              fluid={data.file.childImageSharp.fluid}
              alt="Yes, You Can Become an Author"
            />
          </div>
        </div>
      </section>
      <section className="bg-yellow-400 p-4">
        <div className="flex justify-center items-center w-60 sm:w-full m-auto gap-20 pb-4 text-center">
          <Typography className="text-4xl" fontFamily={"Impact"}>
            Early praise for Yes, You Can
          </Typography>
        </div>
        <div className="flex justify-center items-center">
          <div className="grid grid-cols-1 md:grid-cols-3 justify-center items-center gap-6 max-w-7xl">
            <div>
              <p className="text-center p-6">
                "I just finished reading this book and LOVE IT! It makes me want
                to self-publish right now! " - Leslee
              </p>
              <p className="text-center">
                "This book is exactly what I've been looking for. It provides a
                wealth of information and offers solutions to my questions. It
                helped me to recognize myself as a writer, and I personally feel
                that publishing a book is achievable." - Maria
              </p>
            </div>
            <div>
              <p className="text-center p-6">
                "I think this book would be helpful for someone who is at the
                very beginning stage of their writing journey. It gives great
                tips and points them in the direction of finding more
                information." - Justine
              </p>
              <p className="text-center">
                "Everyone who is a writer can learn, prepare, and publish (if
                they choose) using this book. Even if they don't read the entire
                book, there's something for everyone." - Shannon
              </p>
            </div>
            <div>
              <p className="text-center p-6">
                "This book is an excellent reference. Right from the beginning I
                appreciated the substantive information that one needs to obtain
                and consider when self-publishing." - Shuaa
              </p>
              <p className="text-center">
                "This book has so much good information about habits, social
                media, marketing--all the stuff that is essential to ensure your
                book gets into someone else's hands." - Amanda
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SelfPublishingBook;
