import React from "react";
import Layout from "../components/Layout";
import SelfPublishingBook from "../components/SelfPublishingBook";

export default function () {
  return (
    <Layout>
      <SelfPublishingBook />
    </Layout>
  );
}
